import axios from 'axios'
import { Dialog,Toast   } from 'vant'
import { getToken } from '@/util/auth'
import errorCode from '@/util/errorCode'
import { getSaasId} from "@/util/session-storage-utils";
import {getLanguage} from "./session-storage-utils";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: "http://localhost:8080/gpscloud",
  baseURL: "https://api.suogong.net/gpscloud",
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['saasId'] = getSaasId()
  config.headers['language'] = getLanguage() || "zh"
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 0;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401 || code === -100 || code === -101) {
      Dialog.confirm('系统提示','登录状态已过期，您可以继续留在该页面，或者重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
          location.href = '/index';
      })
        return Promise.reject();
    } else if (code === 500 || code == -1) {
      Toast.fail(msg)
      return Promise.reject(new Error(msg))
    }else if(code === 307){
      //实名认证
      Dialog.confirm({
        title: '提示',
        message: '此功能需要经过账号认证才能使用，请进入个人中心认证',
          confirmButtonText:"立即认证",
          cancelButtonText:"关闭"
      })
          .then(() => {
            window.wx.miniProgram.navigateTo({url: '/pages/user/auth/main'})
          })
          .catch(() => {
            // on cancel
          });
      return Promise.reject();

    } else if (code !== 0) {
      Toast.fail(msg)
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Toast.fail({message,duration:3000})
    return Promise.reject(error)
  }
)

export default service
